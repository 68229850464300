<template>
  <div class="appointmentList-view">
    <p>基本信息</p>
    <commonTable
      :tableData="tableData"
      :loading="loading"
      :paginationVisible="false"
    >
      <template v-slot:table>
        <el-table-column align="center" prop="cardId" label="序号" />
        <el-table-column prop="name" align="center" label="姓名" />
        <!-- <template slot-scope="scope"
            ><img :src="scope.row.avatarUrl" class="w50 h50" />
          </template>
        </el-table-column> -->
        <el-table-column prop="phone" align="center" label="手机号码" />
        <el-table-column prop="personalWx" align="center" label="微信号" />
        <el-table-column prop="position" align="center" label="职位" />
        <el-table-column prop="num" align="center" label="" />
        <el-table-column prop="dealNum" align="center" label="成单客户数" />
        <el-table-column prop="qwNum" align="center" label="企微客户数" />
        <el-table-column prop="createTime" align="center" label="创建时间" />
        <el-table-column prop="withdrawBalance" align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="delete_tap(scope.row.cardId)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-tabs v-model="activeName">
      <el-tab-pane label="我的客户" name="first">
        <exclusiveClients v-if="activeName == 'first'" :detallId="detallId" />
      </el-tab-pane>
      <el-tab-pane label="成单客户" name="second">
        <singleCustomer v-if="activeName == 'second'" :detallId="detallId" />
      </el-tab-pane>
      <el-tab-pane
        v-if="tableData[0].qywxUserid"
        label="企微客户"
        name="thirdly"
      >
        <weChatCustomer v-if="activeName == 'thirdly'" :detallId="detallId" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import exclusiveClients from "./components/memberDetall/exclusiveClients";
import singleCustomer from "./components/memberDetall/singleCustomer";
import weChatCustomer from "./components/memberDetall/weChatCustomer";
import { getCompanyStaffInfo, quitCompany } from "@/api/companyManage";
import { getDataTimeSec } from "@/utils";
export default {
  name: "appointmentList",
  components: {
    commonTable,
    exclusiveClients,
    singleCustomer,
    weChatCustomer,
  },
  data() {
    return {
      value: "",
      tableData: [],
      tableDataList: [],
      optionList: "", //全部分类
      loading: "",
      total: 0, //总条数
      currentPage: 1, //当前页数
      queryDate: "",
      pageNum: 1,
      pageSize: 10,
      detallId: "", //详情id
      searchKey: "",
      countType: 1,
      activeName: "first",
    };
  },
  computed: {
    setDataTimeSec() {
      return (data) => getDataTimeSec(data);
    },
  },
  created() {
    this.detallId = this.$route.query.id;
    if (this.detallId) {
      this.getTableData();
    }
  },
  methods: {
    //请求表格数据
    getTableData() {
      this.loading = true;
      let data = {
        cardId: this.detallId,
      };
      getCompanyStaffInfo(data)
        .then((res) => {
          console.log(res);
          this.loading = false;
          this.tableData = [res.data];
          this.userId = res.data.userId;
          console.log(this.tableData);
          // this.tableDataList = res.data.pageInfo.responseList || [];

          // if(this.tableDataList){
          //   this.tableDataList.forEach(item =>{
          //     item.commissionRate = item.commissionRate+"%";
          //   });
          // }
        })
        .catch((err) => {
          this.loading = false;
          this.tableData = [];
        });
    },
    //   getMemberClient(){
    //     $.cAjax('/crmPc/customer/getCustomerPC',{
    //         data:{
    //             type: 1,
    //             pageNum:this.page,
    //             name: this.searchKey,
    //             cardId: this.cardId,
    //             customerState: this.countType
    //         }
    //     }).then(res=>{
    //         this.clientLists = res.pageInfo.list || [];
    //         this.total = res.pageInfo.total;
    //     })
    // },
    delete_tap() {
      this.$confirm("是否删除该成员?", "提示", {
        onConfirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then((res) => {
        let data = {
          cardId: this.detallId,
          userId: this.userId,
        };
        quitCompany(data).then((res) => {
          console.log(res);
        });
      });
    },
    //当前页变更
    handleCurrentChange(num) {
      this.pageNum = num;
      this.getTableData();
    },
    //当前页查询数据条数变更
    handleSizeChange(num) {
      this.pageSize = num;
      this.getTableData();
    },
  },
};
</script>

<style lang="scss" scoped>
.appointmentList-view {
  background: #ffffff;
  padding: 24px;
}
</style>
